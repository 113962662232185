import { mountSelects, setFormFieldError, removeFormFieldsError, clearFormField, handleFiles, removeFileFromFileList } from 'JS/components/_form.js';
import { post_with_file } from 'JS/components/_request.js';
import { debounce, scrollToSection } from 'JS/components/_functions.js';

const formInitSelects = (parentSelector) => {
    const selects = document.querySelectorAll(parentSelector+' select');
    mountSelects(selects);
}
const formInitContact = (parentSelector, formAction='') => {
    const formWrappers = document.querySelectorAll(parentSelector);
    if(formWrappers.length>0){
        formWrappers.forEach((el)=>{
            const form = el.querySelector('form');
    
            if(form){
                const attachmentsFileInput = form.querySelector('[name="attachments"]');
                if(attachmentsFileInput){
                    attachmentsFileInput.addEventListener('change', (e) => {
                        e.preventDefault();
                        let files = attachmentsFileInput.files ?? '';
                        handleFiles(attachmentsFileInput, files, false);
                    });
                }
                
                form.addEventListener('submit', async function(e){
                    e.preventDefault();
                    
                    if(form.classList.contains('submitting')) return;
                    form.classList.add('submitting');
    
                    let data = {};

                    
                    // general form
                    let full_name_el = form.querySelector('[name="full_name"]');
                    if(full_name_el){
                        data.full_name = full_name_el.value;
                        
                    }
    
                    let email_el = form.querySelector('[name="email"]');
                    if(email_el){
                        data.email = email_el.value;
                        
                    }
    
                    let phone_code_el = form.querySelector('[name="phone_code"]');
                    if(phone_code_el){
                        data.phone_code = phone_code_el.value;
                        
                    }
    
                    let phone_number_el = form.querySelector('[name="phone_number"]');
                    if(phone_number_el){
                        data.phone_number = phone_number_el.value;
                        
                    }

                    let message_el = form.querySelector('[name="message"]');
                    if(message_el){
                        data.message = message_el.value;
                    }

                    let post_id_el = form.querySelector('[name="post_id"]');
                    if(post_id_el){
                        data.post_id = post_id_el.value;
                    }

                    // project
                    let project_id_el = form.querySelector('[name="project_id"]');
                    if(project_id_el){
                        data.project_id = project_id_el.value;
                    }

                    let first_name_el = form.querySelector('[name="first_name"]');
                    if(first_name_el){
                        data.first_name = first_name_el.value;
                    }

                    let last_name_el = form.querySelector('[name="last_name"]');
                    if(last_name_el){
                        data.last_name = last_name_el.value;
                    }

                    let country_el = form.querySelector('[name="country"]');
                    if(country_el){
                        data.country = country_el.value;
                    }

                    let broker_el = form.querySelector('[name="broker"]');
                    if(broker_el){
                        data.broker = broker_el.value;
                    }

                    let purpose_el = form.querySelector('[name="purpose"]');
                    if(purpose_el){
                        data.purpose = purpose_el.value;
                    }

                    let bedroom_el = form.querySelector('[name="bedroom"]');
                    if(bedroom_el){
                        data.bedroom = bedroom_el.value;
                    }

                    let period_el = form.querySelector('[name="period"]');
                    if(period_el){
                        data.period = period_el.value;
                    }
                    // project end
    
                    // let term_el = form.querySelector('[name="term"]');
                    // if(term_el){
                    //     data.term = term_el.value;
                    data.term = true;
                    // }    
    
                    let recaptcha_el = form.querySelector('#g-recaptcha-response');
                    if(recaptcha_el){
                        data.grecaptchaResponse = recaptcha_el.value;
                        
                    }
    
                    let formMessage = form.querySelector('.js-form-message');
                    if(!formMessage) return;

                    let action = '';
                    if(formAction=='event'){
                        action = 'ayat_form_event_contact';
                    }
                    else if(formAction=='contact'){
                        action = 'ayat_form_contact';
                    }
                    else if(formAction=='project'){
                        action = 'ayat_form_project_contact';
                    }
                    else if(formAction=='job'){
                        action = 'ayat_form_job_contact';
                    }
                    else{
                        alert('Something went wrong!');
                        return;
                    }
                    
                    data.attachments = '';

                    let formData = {
                        "action" : action,
                        data : JSON.stringify(data),
                    }

                    if(attachmentsFileInput){
                        formData.files =  attachmentsFileInput.files ?? '';
                    }
    
                    removeFormFieldsError(form, data);
                    formMessage.classList.add('hide');
                    formMessage.classList.remove('success');
                    formMessage.classList.remove('error');
    
                    let res = await post_with_file(formData);
    
                    if(res.success){
                        let message = 'Success';
                        if(res.data.message){
                            message = res.data.message;
                        }
                        formMessage.classList.remove('hide');
                        formMessage.classList.add('success');
                        formMessage.innerHTML = `<p>${message}</p>`;
                        clearFormField(form, data);
                    }
                    else{
                        let message = 'Something went wrong.';
                        if(res.data.message){
                            message = res.data.message;
                        }
                        formMessage.innerHTML = `<p>${message}</p>`;
                        formMessage.classList.remove('hide');
                        formMessage.classList.add('error');
        
                        let grecaptchaChecked = true;
                        if(res.data.errors){
                            for (const [key, value] of Object.entries(res.data.errors)) {
                                if (key=='grecaptchaResponse') {
                                    let field = form.querySelector(`.g-recaptcha`);
                                    setFormFieldError(field, value);
                                    grecaptchaChecked = false;
                                    continue;
                                }
        
                                let field = form.querySelector(`[name="${key}"]`);
                                setFormFieldError(field, value);
                            }
                        }
                        
                        if(grecaptchaChecked){
                            if(grecaptcha){
                                grecaptcha.reset();
                            }
                        }
                    }
                    form.classList.remove('submitting');
                });
            }
        });
    }
}

export { formInitContact, formInitSelects }